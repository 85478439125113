import * as React from "react"
import { Helmet } from "react-helmet"
import { graphql, PageProps } from "gatsby"

import QRCode from "react-qr-code"
import { StringParam, useQueryParam } from "use-query-params"

import DownloadSection from "../components/download"
import Layout from "../components/layouts"
import Seo from "../components/seo"

import { LANG_IT } from "../utils/localization"
import { SiteInformation } from "../utils/site"

const DoodlePage: React.FC<PageProps<SiteInformation>> = ({ data, path }) => {
  const [doodleId] = useQueryParam("id", StringParam)
  const showDoodle = doodleId && doodleId.length === 6

  return (
    <Layout>
      <Helmet>
        <link
          rel="alternate"
          hrefLang={"x-default"}
          href={`${data.site.siteMetadata.siteUrl}/doodle/`}
        />
        <link
          rel="alternate"
          hrefLang={LANG_IT}
          href={`${data.site.siteMetadata.siteUrl}/it/doodle/`}
        />
      </Helmet>
      <Seo
        title={showDoodle ? doodleId : "Doodle"}
        description="Use the phone camera to scan this code and load the doodle in your app."
        path={path}
      />
      <section id="hero">
        <div className="container">
          {showDoodle ? (
            <div className="row">
              <div className="justify-content-center align-self-center text-center col-lg-5 col-md-5 col-sm-5 col-xs-5">
                <QRCode
                  value={`${data.site.siteMetadata.siteUrl}/doodle/${doodleId}`}
                />
              </div>
              <div className="col-md-7 content-box">
                <span>
                  Doodle Code: <b>{doodleId}</b>
                </span>
                <h1>
                  Use the <b>phone camera</b> to scan this code and load the
                  doodle in your app.
                </h1>
                <p>
                  When users open a doodle into an device where the app that is
                  not installed, the app's store page opens. After users install
                  and open the app, they will have access to the canvas to draw.
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </section>
      <DownloadSection />
    </Layout>
  )
}

export default DoodlePage

export const query = graphql`
  query {
    site {
      ...SiteInformation
    }
  }
`
